import axios from "axios/index";

export default new (class ApiService {
  constructor() {
    this.client = axios.create({
      baseURL: "https://api2.qsales.co/", // standart deploy
      //baseURL: window._env_.API_URL,  // for k8s deploy
      headers: {
        Accept: "application/json",
      },
    });

    //this.client.defaults.headers.common["Content-Type"] =
    //  "application/x-www-form-urlencoded";
    //"application/json; charset=utf-8";
    //axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

    this.client.interceptors.request.use(
      (clientConfig) => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
          clientConfig.headers.Authorization = `Bearer ${user.accessToken.token}`;
        }
        return clientConfig;
      },
      (error) => Promise.reject(error)
    );

    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          localStorage.getItem("user")
        ) {
          localStorage.removeItem("user");
        }
        return Promise.reject(error);
      }
    );
  }

  get = (urlSuffix, query = {}, responseType = "json", filename = "") =>
    this.client({
      method: "get",
      url: `/${urlSuffix}/`,
      params: query,
      responseType,
    })
      .then((response) => {
        // if (responseType === "blob") {
        //   Download(response.data, filename);
        // }
        return window.Promise.resolve(response);
      })
      .catch((e) => window.Promise.reject(e));

  post = (urlSuffix, data = {}) =>
    this.client({
      method: "post",
      url: `/${urlSuffix}/`,
      data,
    });

  patch = (urlSuffix, body = {}) =>
    this.client({
      method: "patch",
      url: `/${urlSuffix}/`,
      params: body,
    });

  put = (urlSuffix, body = {}) =>
    this.client({
      method: "put",
      url: `/${urlSuffix}/`,
      params: body,
      //headers: { "Content-Type": "application/json; charset=utf-8" },
    });

  delete = (urlSuffix) =>
    this.client({
      method: "delete",
      url: `/${urlSuffix}/`,
    });
})();
