export const INDEX_PAGE_SIZE_DEFAULT = 10;
export const INDEX_PAGE_SIZE_OPTIONS = [10, 20, 30, 50, 100];
export const PAGINATION = {
  showSizeChanger: true,
  showQuickJumper: false,
  pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
  showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
  total: 0,
  current: 1,
  pageSize: INDEX_PAGE_SIZE_DEFAULT,
};
