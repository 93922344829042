import React, { Component } from "react";
import { Table } from "antd";
import documentsService from "../services/documents.service";
import MapContainer from "./map";
import {
  INDEX_PAGE_SIZE_DEFAULT,
  INDEX_PAGE_SIZE_OPTIONS,
} from "../constants/AppConstants";

export default class DocumentDetails extends Component {
  constructor() {
    super();
    this.state = {
      docRows: [],
      customerName: "",
      customerAddress: "",
      staffName: "",
      docDate: "",
      docTypeName: "",
      latitude: 0,
      longitude: 0,
      meta: {
        page: 1,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0,
      },
    };
  }

  componentDidMount() {
    documentsService.getDocumentById(this.props.match.params.id).then(
      (response) => {
        const documentRows = response.data.docRows.map((item, i) => ({
          key: item.goodCode,
          roundedSumma: item.summa.toFixed(2),
          price: (item.summa / item.quantity).toFixed(2),
          ...item,
        }));

        this.setState({
          customerName: response.data.customerName,
          customerAddress: response.data.customerAddress,
          staffName: response.data.staffName,
          docDate: response.data.docDate,
          docTypeName: response.data.docTypeName,
          latitude: response.data.latitude,
          longitude: response.data.longitude,
          docRows: documentRows,
          meta: { total: response.data.docRows.length },
        });
      },
      (error) => {
        console.log(error);
        this.props.history.push("/login");
      }
    );
  }

  render() {
    const data = this.state.docRows;
    const columns = [
      {
        title: "Назва товару",
        dataIndex: "goodName",
        key: "goodName",
        // render: (text) => <strong>{text}</strong>,
      },
      {
        title: "Кількість",
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: "од.вим.",
        dataIndex: "goodUnitName",
        key: "goodUnitName",
      },
      {
        title: "Ціна",
        dataIndex: "price",
        key: "price",
      },
      {
        title: "Сума",
        dataIndex: "roundedSumma",
        key: "roundedSumma",
      },
    ];
    const pagination = {
      showSizeChanger: true,
      showQuickJumper: false,
      pageSizeOptions: this.state.meta.pageSizeOptions,
      showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,

      total: this.state.meta.total,
      current: this.state.meta.page,
      pageSize: this.state.meta.pageSize,
    };

    const locationCenter = {
      address: "", //this.state.customerAddress,
      lat: 49.843739, //this.state.latitude,
      lng: 24.013264, //this.state.longitude,
    };

    const location = {
      address: this.state.customerAddress,
      lat: this.state.latitude,
      lng: this.state.longitude,
    };

    return (
      <div className="container">
        <header className="jumbotron">
          <h4>Деталі документу</h4>
          <h6>
            Тип документу: {this.state.docTypeName} від {this.state.docDate}
          </h6>
          <h6>Клієнт: {this.state.customerName}</h6>
          <h6>Адреса: {this.state.customerAddress}</h6>
          <h6>Торговий: {this.state.staffName}</h6>
        </header>
        <MapContainer
          locationCenter={locationCenter}
          location={location}
          zoomLevel={7}
        />
        <Table
          dataSource={data}
          columns={columns}
          rowKey="key"
          pagination={pagination}
        />
      </div>
    );
  }
}
