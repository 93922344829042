import api from "./api";

class DocumentsService {
  getDocumentsByCondition(page, pageSize, conditions) {
    return api.post("Documents/GetDocumentsPage", {
      page: page,
      pageSize: pageSize,
      conditions: conditions,
    });
  }

  getDocumentById(id) {
    return api.get("Documents", {
      documentId: id,
    });
  }
}

export default new DocumentsService();
