import React from "react";
import GoogleMapReact from "google-map-react";
import "./map.css";
import Marker from "./marker";

const Map = ({ locationCenter, location, zoomLevel }) => (
  <div className="map">
    <div className="google-map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDh8OYrvM9-3bNAAx7Yjnn4rt9zbpiNHuo" }}
        defaultCenter={locationCenter}
        defaultZoom={zoomLevel}
      >
        <Marker text={location.address} lat={location.lat} lng={location.lng} />
      </GoogleMapReact>
    </div>
  </div>
);

export default Map;
