import React, { Component } from "react";
import { Table } from "antd";
import {
  INDEX_PAGE_SIZE_DEFAULT,
  INDEX_PAGE_SIZE_OPTIONS,
  PAGINATION,
} from "../constants/AppConstants";
import staffsService from "../services/user.service";

export default class StaffsList extends Component {
  constructor() {
    super();
    this.state = {
      allStaffs: [],
      foundStaffs: [],
      tableConfiguration: {
        bordered: true,
        loading: false,
        pagination: true,
        size: "small",
        scroll: undefined,
      },
      searchStaffText: "",
      searchedColumn: "",
      meta: {
        page: 1,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0,
      },
    };
  }

  async componentDidMount() {
    let staffsFromServer = await staffsService.getAllStaffs();
    let mappedStaffs = staffsFromServer.data.map((item, i) => ({
      key: item.code,
      ...item,
    }));
    this.setState({
      allStaffs: mappedStaffs,
      meta: { total: staffsFromServer.data.total },
    });
  }

  onClickRow = (record) => {
    return {
      onClick: () => {
        this.props.history.push("/staffs/" + record.key);
      },
    };
  };

  render() {
    const data = this.state.allStaffs;
    const columns = [
      {
        title: <div style={{ fontWeight: "bold", fontSize: 18 }}>Код</div>,
        dataIndex: "code",
        key: "code",
      },
      {
        title: <div style={{ fontWeight: "bold", fontSize: 18 }}>Прізвище</div>,
        dataIndex: "name",
        key: "name",
        render: (text) => <strong>{text}</strong>,
      },
    ];
    PAGINATION.total = this.state.meta.total;
    PAGINATION.pageSize = this.state.meta.pageSize;

    return (
      <div className="container">
        <header className="jumbotron">
          <h4>Модуль управління працівниками</h4>
        </header>
        <Table
          dataSource={data}
          columns={columns}
          rowKey="key"
          //   rowClassName={(record, index) =>
          //     record.status > 2 ? "green" : "red"
          //   }
          //   onChange={this.handleTableChange}
          onRow={this.onClickRow}
          pagination={PAGINATION}
        />
      </div>
    );
  }
}
