import React, { Component } from "react";
import { Button, Dropdown, Menu, Table } from "antd";
import "antd/dist/antd.css";
import routesService from "../services/routes.service";
import staffsService from "../services/user.service";
import {
  INDEX_PAGE_SIZE_DEFAULT,
  INDEX_PAGE_SIZE_OPTIONS,
} from "../constants/AppConstants";

export default class RoutesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeRecords: [],
      staffs: [],
      staffForFilter: {
        code: null,
        name: "Вибір торгового представника(наведіть курсор на цей надпис)",
      },
      tableConfiguration: {
        bordered: true,
        loading: false,
        pagination: true,
        size: "small",
        // title,
        // showHeader,
        // footer,
        scroll: undefined,
      },
      meta: {
        page: 1,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0,
      },
    };
  }

  componentDidMount() {
    routesService.getRoutesByCondition(1, INDEX_PAGE_SIZE_DEFAULT).then(
      async (response) => {
        const result = response.data.items.map((item, i) => ({
          key: item.code,
          ...item,
        }));
        let staffsFromServer = await staffsService.getAllStaffs();
        this.setState({
          staffs: staffsFromServer.data,
          routeRecords: result,
          meta: { total: response.data.total },
        });
      },
      (error) => {
        console.log(error);
        this.props.history.push("/login");
      }
    );
  }

  handleUpdateRoute(record) {
    this.props.history.push({
      pathname: "/routeslist/" + record.code + "/" + record.name,
      staffs: this.state.staffs,
    });
  }

  handleTableChange = (pagination, filters, sorter) => {
    routesService
      .getRoutesByCondition(pagination.current, pagination.pageSize, {
        order: sorter.hasOwnProperty("column") ? sorter : false,
      })
      .then(
        (response) => {
          const result = response.data.items.map((item, i) => ({
            key: item.code,
            ...item,
          }));
          this.setState({
            routeRecords: result,
            meta: { total: response.data.total },
          });
        },
        (error) => {
          this.props.history.push("/login");
        }
      );
  };

  addNewRoute = () => {
    console.log("Start adding new route");
    this.props.history.push({
      pathname: "/newroute",
    });
  };
  // paginationOptions = {
  //   showSizeChanger: true,
  //   showQuickJumper: false,
  //   onShowSizeChange: (_, pageSize) => {
  //     // this.props.dispatch($pageSize(pageSize));
  //     // this.props.dispatch($fetchIndex())));
  //   },
  //   onChange: (page) => {
  //     // this.props.dispatch($page(page));
  //     // this.props.dispatch($fetchIndex())));
  //   },
  //   pageSizeOptions: this.state.meta.pageSizeOptions,
  //   total: this.state.meta.total,
  //   showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
  // };

  staffChoise = async ({ key }) => {
    let filteredRoutes = await routesService.getRoutesByStaffCodes([key]);
    const result = filteredRoutes.data.map((item, i) => ({
      key: item.code,
      ...item,
    }));

    let choosenStaff = this.state.staffs.find((s) => s.code === key);
    this.setState({
      routeRecords: result,
      meta: { total: filteredRoutes.data.length },
      staffForFilter: choosenStaff,
    });
  };

  render() {
    const data = this.state.routeRecords;
    const columns = [
      {
        title: "Код маршруту",
        dataIndex: "code",
        key: "code",
      },
      {
        title: "Назва маршруту",
        dataIndex: "name",
        key: "name",
        render: (text) => <strong>{text}</strong>,
      },
      {
        title: "Дії",
        key: "action",
        render: (text, record) => (
          <span>
            <Button
              type="loading"
              size="small"
              onClick={() => this.handleUpdateRoute(record)}
            >
              Редагувати
            </Button>
          </span>
        ),
      },
    ];
    const pagination = {
      //...this.paginationOptions,
      showSizeChanger: true,
      showQuickJumper: false,
      pageSizeOptions: this.state.meta.pageSizeOptions,
      showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,

      total: this.state.meta.total,
      current: this.state.meta.page,
      pageSize: this.state.meta.pageSize,
    };

    let staffItems = this.state.staffs.map((s, i) => {
      return (
        <Menu.Item key={s.code}>
          {s.code} {s.name}
        </Menu.Item>
      );
    });
    const menu = <Menu onClick={this.staffChoise}>{staffItems}</Menu>;

    return (
      <div className="container">
        <header className="jumbotron">
          <h4>Модуль управління маршрутами</h4>
          <h6>
            Щоб відфільтрувати маршрути потрібного торгового представника
            наведіть курсор мишки на напис червоним кольром і виберіть
            потрібного Вам торгового у випадаючому списку
          </h6>
          <h6>
            Щоб переглянути список клієнтів у маршруті, а також додавати нових
            клієнтів чи удаляти існуючих з маршруту клікніть на напис
            "Редагувати" у колонці "Дії""
          </h6>
        </header>
        <div className="row justify-content-end">
          <div className="col-md-6">
            <Dropdown overlay={menu}>
              <h6 style={{ color: "red" }}>{this.state.staffForFilter.name}</h6>
            </Dropdown>
          </div>
          <div className="col-md-3 offset-md-3">
            <Button
              type="primary"
              disabled={this.state.staffForFilter.code == null}
              onClick={this.addNewRoute}
            >
              Додати новий маршрут
            </Button>
          </div>
        </div>
        <Table
          dataSource={data}
          // {...this.state.tableConfiguration}
          columns={columns}
          rowKey="key"
          onChange={this.handleTableChange}
          pagination={pagination}
        />
      </div>
    );
  }
}
