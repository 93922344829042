import React, { Component } from "react";
import { AutoComplete, Button, DatePicker, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { inject, observer } from "mobx-react";
import "../index.css";
import {
  INDEX_PAGE_SIZE_DEFAULT,
  INDEX_PAGE_SIZE_OPTIONS,
  PAGINATION,
} from "../constants/AppConstants";
import documentsService from "../services/documents.service";
import routesService from "../services/routes.service";
import staffsService from "../services/user.service";

class DocumentsList extends Component {
  constructor() {
    super();
    this.state = {
      documents: [],
      foundCustomers: [],
      allStaffs: [],
      foundStaffs: [],
      tableConfiguration: {
        bordered: true,
        loading: false,
        pagination: true,
        size: "small",
        scroll: undefined,
      },
      searchCustText: "",
      searchStaffText: "",
      searchDate: "",
      searchedColumn: "",
      meta: {
        page: 1,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0,
      },
    };
  }

  componentDidMount() {
    var conditions = [];
    var condition = "";

    let custCodeFilter = this.props.MainStore.docFilterCustId;
    if (custCodeFilter != null && custCodeFilter !== "") {
      condition = 'customerCode=="' + custCodeFilter + '"';
    }

    let staffCodeFilter = this.props.MainStore.docFilterStaffId;
    if (staffCodeFilter != null && staffCodeFilter !== "") {
      if (condition.length > 0) condition = condition + " and ";
      condition = condition + 'staffCode=="' + staffCodeFilter + '"';
    }

    if (condition.length > 0) conditions.push(condition);

    documentsService
      .getDocumentsByCondition(1, INDEX_PAGE_SIZE_DEFAULT, conditions)
      .then(
        async (response) => {
          const result = response.data.items.map((item, i) => ({
            key: item.id,
            ...item,
          }));
          let staffsFromServer = await staffsService.getAllStaffs();
          let mappedStaffs = staffsFromServer.data.map((item, i) => ({
            key: item.code,
            value: item.name,
          }));

          this.setState({
            documents: result,
            allStaffs: mappedStaffs,
            meta: { total: response.data.total },
          });
        },
        (error) => {
          this.props.history.push("/login");
        }
      );

    this.setState({
      searchCustText: this.props.MainStore.docFilterCustName,
      searchStaffText: this.props.MainStore.docFilterStaffName,
    });
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        {(dataIndex === "customerCode" || dataIndex === "staffCode") && (
          <AutoComplete
            ref={(node) => {
              this.searchInput = node;
            }}
            options={
              dataIndex === "customerCode"
                ? this.state.foundCustomers
                : this.state.foundStaffs
            }
            value={
              dataIndex === "customerCode"
                ? this.state.searchCustText
                : this.state.searchStaffText
            }
            placeholder="почніть вводити тут"
            onSelect={(value) => {
              selectedKeys.push(value);
            }}
            onSearch={
              dataIndex === "customerCode"
                ? this.handleCustomersSearch
                : this.handleStaffsSearch
            }
            onChange={
              dataIndex === "customerCode"
                ? this.onChangeCust
                : this.onChangeStaff
            }
            style={{
              width: 208,
              marginBottom: 8,
              display: "block",
            }}
          />
        )}
        {dataIndex === "docDate" && (
          <DatePicker
            style={{
              width: 208,
              marginBottom: 8,
              display: "block",
            }}
            onChange={(date, dateString) => {
              if (date) selectedKeys.push(date.toISOString().split("T")[0]);
            }}
            format={"DD/MM/YYYY"}
          />
        )}
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            Шукати
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 100 }}
          >
            Скинути
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleCustomersSearch = (value) => {
    if (value.length > 2) {
      routesService.searchCustomers(value).then(
        (response) => {
          const result = response.data.map((item, i) => ({
            key: item.code,
            value: item.name + ", " + item.address,
          }));

          this.setState({
            foundCustomers: result,
          });
        },
        (error) => {
          console.log(error);
          this.props.history.push("/login");
        }
      );
    }
  };

  handleStaffsSearch = (val) => {
    if (val.length > 2) {
      let result = this.state.allStaffs.filter((s) =>
        s.value.toLowerCase().includes(val)
      );
      this.setState({
        foundStaffs: result,
      });
    }
  };

  handleReset = (clearFilters, dataIndex) => {
    clearFilters();

    switch (dataIndex) {
      case "customerCode":
        this.setState({
          searchCustText: "",
          foundCustomers: [],
        });
        this.props.MainStore.setFilterCustId("");
        this.props.MainStore.setFilterCustName("");
        this.componentDidMount();
        break;
      case "staffCode":
        this.setState({ searchStaffText: "", foundStaffs: [] });
        this.props.MainStore.setFilterStaffId("");
        this.props.MainStore.setFilterStaffName("");
        this.componentDidMount();
        break;
      case "docDate":
        this.setState({ searchDate: "" });
        break;

      default:
        break;
    }
  };

  onChangeCust = (data) => {
    this.setState({ searchCustText: data });
  };

  onChangeStaff = (data) => {
    this.setState({ searchStaffText: data });
  };

  onClickRow = (record) => {
    return {
      onClick: () => {
        this.props.history.push("/documents/" + record.id);
      },
    };
  };

  handleTableChange = (pagination, filters, sorter) => {
    var conditions = [];
    var condition = "";
    if (filters.customerCode != null) {
      var foundCust = this.state.foundCustomers.find(
        (c) => c.value === filters.customerCode[0]
      );
      condition = 'customerCode=="' + foundCust.key + '"';
      let custName = foundCust.value.substring(0, foundCust.value.indexOf(","));
      this.props.MainStore.setFilterCustId(foundCust.key);
      this.props.MainStore.setFilterCustName(custName);
    }
    if (filters.staffCode != null) {
      var foundStaff = this.state.foundStaffs.find(
        (c) => c.value === filters.staffCode[0]
      );
      if (condition.length > 0) condition = condition + " and ";
      condition = condition + 'staffCode=="' + foundStaff.key + '"';
      this.props.MainStore.setFilterStaffId(foundStaff.key);
      this.props.MainStore.setFilterStaffName(foundStaff.value);
    }
    if (filters.docDate != null) {
      // var foundStaff = this.state.foundStaffs.find(
      //   (c) => c.value === filters.staffCode[0]
      // );
      if (condition.length > 0) condition = condition + " and ";
      condition = condition + 'Docdate.Date="' + filters.docDate[0] + '"';
    }

    if (condition.length > 0) conditions.push(condition);

    documentsService
      .getDocumentsByCondition(
        pagination.current,
        pagination.pageSize,
        conditions
      )
      .then(
        (response) => {
          const result = response.data.items.map((item, i) => ({
            key: item.id,
            ...item,
          }));
          this.setState({
            documents: result,
            meta: { total: response.data.total },
          });
        },
        (error) => {
          this.props.history.push("/login");
        }
      );
  };

  render() {
    const data = this.state.documents;
    const columns = [
      {
        title: <div style={{ fontWeight: "bold", fontSize: 18 }}>ID</div>,
        width: 80,
        dataIndex: "id",
        key: "id",
      },
      {
        title: (
          <div style={{ fontWeight: "bold", fontSize: 18 }}>Дата док.</div>
        ),
        dataIndex: "docDate",
        key: "docDate",
        ...this.getColumnSearchProps("docDate"),
      },
      {
        title: (
          <div style={{ fontWeight: "bold", fontSize: 18 }}>Торг. агент</div>
        ),
        dataIndex: "staffName",
        key: "staffCode",
        ...this.getColumnSearchProps("staffCode"),
      },
      {
        title: <div style={{ fontWeight: "bold", fontSize: 18 }}>Клієнт</div>,
        dataIndex: "customerName",
        key: "customerCode",
        ...this.getColumnSearchProps("customerCode"),
      },
      {
        title: <div style={{ fontWeight: "bold", fontSize: 18 }}>Сума</div>,
        dataIndex: "summa",
        key: "summa",
      },
      {
        title: (
          <div style={{ fontWeight: "bold", fontSize: 18 }}>Тип документа</div>
        ),
        dataIndex: "docTypeName",
        key: "docTypeName",
      },
      {
        title: <div style={{ fontWeight: "bold", fontSize: 18 }}>Примітка</div>,
        dataIndex: "memo",
        key: "memo",
      },
      {
        title: (
          <div style={{ fontWeight: "bold", fontSize: 18 }}>
            Час початку вводу
          </div>
        ),
        dataIndex: "docTime",
        key: "docTime",
      },
      {
        title: (
          <div style={{ fontWeight: "bold", fontSize: 18 }}>Час отримання</div>
        ),
        dataIndex: "modified",
        key: "modified",
      },
    ];
    PAGINATION.total = this.state.meta.total;
    PAGINATION.pageSize = this.state.meta.pageSize;

    return (
      <div className="container">
        <header className="jumbotron">
          <h4>Модуль перегляду документів</h4>
        </header>
        <Table
          dataSource={data}
          columns={columns}
          rowKey="key"
          rowClassName={(record, index) =>
            record.status > 2 ? "green" : "red"
          }
          onChange={this.handleTableChange}
          onRow={this.onClickRow}
          pagination={PAGINATION}
        />
      </div>
    );
  }
}

export default inject("MainStore")(observer(DocumentsList));
