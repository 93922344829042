import React, { Component } from "react";

export default class StaffDetails extends Component {
  render() {
    return (
      <div className="container">
        <header className="jumbotron">
          <h4>Деталі працівника</h4>
          {/* <h6>
            Тип документу: {this.state.docTypeName} від {this.state.docDate}
          </h6>
          <h6>Клієнт: {this.state.customerName}</h6>
          <h6>Адреса: {this.state.customerAddress}</h6>
          <h6>Торговий: {this.state.staffName}</h6> */}
        </header>
      </div>
    );
  }
}
