import api from "./api";

class RoutesService {
  getRoutesByCondition(page, pageSize) {
    return api.post("Routes/GetPage", {
      page: page,
      pageSize: pageSize,
    });
  }

  getRouteDetails(routeCode) {
    return api.get("Routes", { routeCode: routeCode });
  }

  getRoutesByStaffCodes(staffCodes) {
    return api.post("Routes/GetRoutesByStaffCodes", {
      staffCodes: staffCodes,
    });
  }

  searchCustomers(searchString) {
    return api.get("Customers/Search", { searchString: searchString });
  }

  addCustomerToRoute(routeCode, customerCode) {
    return api.post("Routes/AddCustomerRoute", {
      routeCode: routeCode,
      customerCode: customerCode,
    });
  }

  removeCustomerFromRoute(customerRouteId) {
    return api.patch("Routes", {
      customerRouteId: customerRouteId,
    });
  }

  updateStaffRoutes(staffRoutes) {
    return api.post("Routes/StaffRoutesBulkInsertOrUpdate", {
      staffRoutes: staffRoutes,
    });
  }

  addRoutes(newRoutes) {
    return api.post("Routes/RoutesBulkInsertOrUpdate", newRoutes);
  }
}

export default new RoutesService();
