import React, { Component } from "react";
import {
  Button,
  Table,
  Tabs,
  Transfer,
  AutoComplete,
  notification,
  Popconfirm,
} from "antd";
import "antd/dist/antd.css";
import { DeleteFilled, QuestionCircleOutlined } from "@ant-design/icons";
import routesService from "../services/routes.service";

const INDEX_PAGE_SIZE_DEFAULT = 10;
const INDEX_PAGE_SIZE_OPTIONS = [10, 20, 30, 50, 100];
const { TabPane } = Tabs;

export default class RouteDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      staffs: [],
      targetStaffKeys: [],
      initialTargetStaffKeys: [],
      selectedStaffKeys: [],
      foundCustomers: [],
      routeId: "",
      currentCustomer: null,
      autoCompleteValue: "",
      meta: {
        page: 1,
        pageSize: INDEX_PAGE_SIZE_DEFAULT,
        pageSizeOptions: INDEX_PAGE_SIZE_OPTIONS,
        pageTotal: 1,
        total: 0,
      },
    };
  }

  componentDidMount() {
    routesService.getRouteDetails(this.props.match.params.id).then(
      (response) => {
        const result = response.data.customers
          .filter((item) => item.isDeleted === false)
          .map((item, i) => ({
            key: item.customerDto.code,
            id: item.id,
            name: item.customerDto.name,
            address: item.customerDto.address,
            ...item,
          }));
        const allStaffs = this.props.location.staffs.map((item, i) => ({
          key: item.code,
          ...item,
        }));
        let selectedStaffCodes = response.data.staffs
          .filter((item) => !item.isDeleted)
          .map((item) => item.staffCode);
        this.setState({
          customers: result,
          staffs: allStaffs,
          targetStaffKeys: selectedStaffCodes,
          initialTargetStaffKeys: selectedStaffCodes,
          meta: {
            total: response.data.customers.filter(
              (item) => item.isDeleted === false
            ).length,
          },
          routeId: this.props.match.params.id,
        });
      },
      (error) => {
        console.log(error);
        this.props.history.push("/login");
      }
    );
  }

  handleSearch = (value) => {
    if (value.length > 2) {
      routesService.searchCustomers(value).then(
        (response) => {
          const result = response.data.map((item, i) => ({
            key: item.code,
            value: item.name + ", " + item.address,
          }));

          this.setState({
            foundCustomers: result,
          });
        },
        (error) => {
          console.log(error);
          this.props.history.push("/login");
        }
      );
    }
  };

  handleTransferChange = (nextTargetKeys, direction, moveKeys) => {
    this.setState({ targetStaffKeys: nextTargetKeys });
  };

  handleTransferSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedStaffKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
    });
  };

  addClientToRoute = (value) => {
    var foundCust = this.state.foundCustomers.find((c) => c.value === value);
    this.setState({ currentCustomer: foundCust });
  };

  removeClientFromRoute(record) {
    routesService.removeCustomerFromRoute(record.id).then(
      (response) => {
        let removedElemIdx = this.state.customers.indexOf(record);
        const custs = this.state.customers.slice();
        custs.splice(removedElemIdx, 1);
        this.setState({
          customers: custs,
          meta: { total: custs.length },
        });
      },
      (error) => {
        if (error.response.status === 401) {
          this.props.history.push("/login");
        } else {
          notification["warning"]({
            message: "Помилка видалення " + error.response.status,
          });
        }
      }
    );
  }

  saveStaffRoutes = () => {
    let staffRoutes = [];
    if (this.state.targetStaffKeys.length > 0) {
      staffRoutes = this.state.targetStaffKeys
        .filter((item) => !this.state.initialTargetStaffKeys.includes(item))
        .map((item) => ({
          staffCode: item,
          routeCode: this.state.routeId,
          isDeleted: false,
        }));
    }
    this.state.initialTargetStaffKeys.forEach((element) => {
      if (!this.state.targetStaffKeys.includes(element)) {
        staffRoutes.push({
          staffCode: element,
          routeCode: this.state.routeId,
          isDeleted: true,
        });
      }
    });
    routesService
      .updateStaffRoutes(staffRoutes)
      .then((response) => {
        notification["success"]({
          message: "Успішно оновлено привязки торгових до маршруту!",
        });
        this.props.history.goBack();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.props.history.push("/login");
        } else {
          notification["warning"]({
            message: "Помилка збереження" + err.response.status,
            description: "X",
          });
        }
      });
  };

  callAddClientApi = () => {
    let existingElem = this.state.customers.find(
      (e) => e.key === this.state.currentCustomer.key
    );
    if (existingElem) {
      notification["warning"]({
        message: "Клієнт вже є у маршруті!",
      });
      return;
    }
    routesService
      .addCustomerToRoute(
        this.props.match.params.id,
        this.state.currentCustomer.key
      )
      .then(async (response) => {
        let newcustomers = await routesService.getRouteDetails(
          this.props.match.params.id
        );
        const result = newcustomers.data.customers
          .filter((item) => item.isDeleted === false)
          .map((item, i) => ({
            key: item.customerDto.code,
            name: item.customerDto.name,
            address: item.customerDto.address,
            ...item,
          }));
        this.setState({
          customers: result,
          meta: {
            total: newcustomers.data.customers.filter(
              (item) => item.isDeleted === false
            ).length,
          },
        });

        notification["success"]({
          message: "Успішно додано!",
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.props.history.push("/login");
        } else {
          notification["warning"]({
            message: "Помилка додавання " + err.response.status,
            description: "X",
          });
        }
      })
      .finally(() => {
        this.setState({
          currentCustomer: null,
          autoCompleteValue: "",
        });
      });
  };

  onChange = (data) => {
    this.setState({ autoCompleteValue: data });
  };

  handleTableChange = (pagination, filters, sorter) => {
    let customersCopy = this.state.customers.slice();
    if (sorter.order === "ascend") {
      customersCopy.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
    } else {
      customersCopy.sort((a, b) =>
        a.name < b.name ? 1 : b.name < a.name ? -1 : 0
      );
    }
    this.setState({ customers: customersCopy });
  };

  render() {
    const columns = [
      {
        title: "Назва клієнта",
        dataIndex: "name",
        key: "name",
        sorter: "name",
        render: (text) => <strong>{text}</strong>,
      },
      {
        title: "Адреса клієнта",
        dataIndex: "address",
        key: "address",
        render: (text) => <strong>{text}</strong>,
      },
      {
        title: "Дії",
        key: "action",
        render: (text, record) => (
          <span>
            <Popconfirm
              title="Справді видалити з маршруту?"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={() => this.removeClientFromRoute(record)}
              okText="Так"
              cancelText="Ні"
            >
              <Button
                type="danger"
                size="small"
                shape="circle"
                icon={<DeleteFilled />}
              ></Button>
            </Popconfirm>
          </span>
        ),
      },
    ];
    const pagination = {
      showSizeChanger: true,
      showQuickJumper: false,
      pageSizeOptions: this.state.meta.pageSizeOptions,
      showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,

      total: this.state.meta.total,
      current: this.state.meta.page,
      pageSize: this.state.meta.pageSize,
    };
    const options = this.state.foundCustomers;
    const { targetStaffKeys, selectedStaffKeys } = this.state;
    return (
      <div className="container">
        <header className="jumbotron">
          <h4>Вибраний маршрут: {this.props.match.params.name}</h4>
        </header>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Клієнти привязані до маршруту" key="1">
            <h5
              style={{
                color: "green",
                fontStyle: "italic",
                fontWeight: "bold",
              }}
            >
              Щоб видалити клієнта з маршруту натисніть на червону іконку з
              корзиною навпроти клієнта
            </h5>
            <AutoComplete
              style={{ width: 500, marginBottom: 50 }}
              options={options}
              value={this.state.autoCompleteValue}
              placeholder="почніть вводити назву чи адресу клієнта"
              onSelect={this.addClientToRoute}
              onSearch={this.handleSearch}
              onChange={this.onChange}
            />
            <Button
              type="primary"
              disabled={this.state.currentCustomer == null}
              onClick={this.callAddClientApi}
            >
              Додати
            </Button>
            <Table
              dataSource={this.state.customers}
              columns={columns}
              rowKey="key"
              onChange={this.handleTableChange}
              pagination={pagination}
            />
          </TabPane>
          <TabPane tab="Торгові привязані до маршруту" key="2">
            <>
              <Transfer
                dataSource={this.state.staffs}
                titles={["Усі торгові", "Привязані до маршруту"]}
                targetKeys={targetStaffKeys}
                selectedKeys={selectedStaffKeys}
                onChange={this.handleTransferChange}
                onSelectChange={this.handleTransferSelectChange}
                // onScroll={this.handleScroll}
                listStyle={{
                  fontWeight: "bold",
                  width: "100%",
                }}
                render={(item) => item.name}
                oneWay
              />
              <div className="row justify-content-center">
                <Button
                  style={{
                    width: 150,
                    marginTop: 10,
                  }}
                  type="primary"
                  disabled={
                    this.state.targetStaffKeys.length ===
                      this.state.initialTargetStaffKeys.length &&
                    this.state.targetStaffKeys.every(
                      (value, index) =>
                        value === this.state.initialTargetStaffKeys[index]
                    )
                  }
                  onClick={this.saveStaffRoutes}
                >
                  Зберегти
                </Button>
              </div>
            </>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}
