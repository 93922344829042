import { action, decorate, observable } from "mobx";

export default class MainStore {
  //storeMap = observable(new Map());
  //storeArray = observable([]);
  //storeBoolean = false;
  docFilterStaffId = null;
  docFilterStaffName = "";
  docFilterCustId = null;
  docFilterCustName = "";

  setFilterStaffId(data: Object) {
    if (data != null) {
      this.docFilterStaffId = data;
    }
  }

  setFilterStaffName(data: Object) {
    if (data != null) {
      this.docFilterStaffName = data;
    }
  }

  setFilterCustId(data: Object) {
    if (data != null) {
      this.docFilterCustId = data;
    }
  }

  setFilterCustName(data: Object) {
    if (data != null) {
      this.docFilterCustName = data;
    }
  }

  // get storeMapSize() {
  //   return this.storeMap.size;
  // }
}

decorate(MainStore, {
  docFilterStaffId: observable,
  docFilterStaffName: observable,
  docFilterCustId: observable,
  docFilterCustName: observable,
  setFilterStaffId: action,
  setFilterStaffName: action,
  setFilterCustId: action,
  setFilterCustName: action,
  //storeMapSize: computed,
});
