import { Button, Input, notification } from "antd";
import React, { Component } from "react";
import routesService from "../services/routes.service";

export default class RouteNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputCode: "",
      inputName: "",
    };
  }

  changeRouteCode = (e) => {
    this.setState({ inputCode: e.target.value });
  };

  changeRouteName = (e) => {
    this.setState({ inputName: e.target.value });
  };

  discardAndClose = () => {
    this.props.history.goBack();
  };

  saveAndClose = () => {
    if (!this.state.inputCode || !this.state.inputName) {
      notification["warning"]({
        message: "Усі поля повинні бути заповненими",
      });
      return;
    }
    let newRoutes = [
      { code: this.state.inputCode, name: this.state.inputName },
    ];
    routesService
      .addRoutes(newRoutes)
      .then(() => {
        notification["success"]({
          message: "Успішно додано новий маршрут",
        });
        this.props.history.goBack();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.props.history.push("/login");
        } else {
          notification["warning"]({
            message: "Помилка створення маршруту: " + err.response.status,
          });
        }
      });
  };

  render() {
    return (
      <div className="container">
        <h4 className="text-center" style={{ marginBottom: 50 }}>
          Новий маршрут
        </h4>
        <div className="row">
          <div className="col">
            <h5>Код:</h5>
          </div>
          <div className="col">
            <Input
              placeholder="Код маршруту"
              value={this.state.inputCode || ""}
              onChange={this.changeRouteCode}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h5>Назва:</h5>
          </div>
          <div className="col">
            <Input
              placeholder="Назва маршруту"
              value={this.state.inputName || ""}
              onChange={this.changeRouteName}
            />
          </div>
        </div>
        <div className="row" style={{ marginTop: 50 }}>
          <div className="col text-right">
            <Button type="primary" onClick={this.saveAndClose}>
              Зберегти
            </Button>
          </div>
          <div className="col">
            <Button type="primary" onClick={this.discardAndClose}>
              Закрити
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
